<template>
  <div :class="$style.subHeader">
    <ul>
      <li
        v-for="(link, index) in links"
        :class="{ [$style.active]: checkRoute(index) }"
        :key="index"
      >
        <router-link :to="link.to">{{ link.text }}</router-link>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'subHeader',
  props: {
    userId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      links: [
        { text: 'Личный кабинет', to: `/addwine/users/${this.userId}` },
        { text: 'Заказы', to: `/addwine/users/${this.userId}/orders` },
        { text: 'Бонусная программа', to: `/addwine/users/${this.userId}/bonus-transactions` },
        { text: 'Подписки на товары', to: `/addwine/users/${this.userId}/subscriptions` }
      ],

      routes: ['AddwineUsersEdit', 'AddwineUsersOrders', 'AddwineUsersBonusTransactions', 'AddwineUsersSubscriptions']
    }
  },
  methods: {
    checkRoute(index) {
      return this.routes[index] == this.$route.name
    }
  }
}
</script>

<style lang="scss" module>
.subHeader {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;

  ul {
    display: flex;
    padding: 0;
    list-style-type: none;
    > li {
      padding: 0.625rem;
      border-radius: 1rem;
      margin: 0 0.625rem;
      opacity: 0.8;
      transition: all 0.3s ease;
      &.active {
        opacity: 1;
        transition: all 0.3s ease;
        background: $white;
      }
      a {
        color: $black-gray;
        font-weight: bold;
        text-decoration: none;
      }
      &:hover {
        opacity: 1;
        transition: all 0.3s ease;
        background: $white;
      }
    }
  }
}
</style>
